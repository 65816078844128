import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgTi1W from '../generated/svg/ti-1w'
import SvgTi2W from '../generated/svg/ti-2w'
import SvgTi3W from '../generated/svg/ti-3w'
import SvgTi4W from '../generated/svg/ti-4w'
import SvgTi5W from '../generated/svg/ti-5w'
import classNames from 'classnames'
import { querySelectorWithShadow } from '../utils/query-selector-with-shadow'

const Outer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--blue);
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s;
  &.open {
    opacity: 1;
    pointer-events: initial;
  }
`

const Inner = styled.div`
  padding: 0 1.875rem;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const List = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-direction: column;
`

const Item = styled.a`
  color: white;
  display: flex;
  align-items: center;
  gap: 1.25rem;
  svg {
    display: block;
    height: 2.1875rem;
    width: 2.4375rem;
  }
`

type MenuProps = {
  open: boolean
  onClose: () => void
}
export const Menu: FunctionComponent<MenuProps> = ({open, onClose}) => {
  return <Outer className={classNames({open})}>
    <Inner>
      <List>
        <Item href="/#about" onClick={onClose}>
          <SvgTi1W />
            ふるさとときめきプロジェクトとは
        </Item>
        <Item href="/#shops" onClick={onClose}>
          <SvgTi2W />
            参加店(優待)一覧
        </Item>
        <Item href="/#questionnaire" onClick={onClose}>
          <SvgTi4W />
            皆様の声をお届けください
        </Item>
        <Item href="/#diners" onClick={onClose}>
          <SvgTi5W />
            ダイナースクラブカード
        </Item>
      </List>
    </Inner>

  </Outer>
}
