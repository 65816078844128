import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import SvgLogo1 from '../generated/svg/logo1'
import { Text } from './text'
import { RestoreScroll } from './restore-scroll'
import classNames from 'classnames'
import { Menu } from './menu'

const Outer = styled.div`
  border-bottom: 1px solid #BABABA;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.4s, transform 0.4s;
  background-color: white;
  /* &.hide {
    opacity: 0;
    transform: translateY(-100%);
  } */
`

const Inner = styled.div`
  margin-left: var(--page-margin);
  margin-right: var(--page-margin);
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  display: flex;
  @media (max-width: 40rem){
    padding-top: 0.75rem;
    padding-bottom: 0.625rem;
  }
`

const Logo = styled.a`
  margin-right: auto;
  svg {
    height: 3.375rem;
    display: block;
    width: auto;
    @media (max-width: 40rem){
      height: 2.3125rem;
    }
  }
`

const Nav = styled.div`
  display: flex;
  align-items: stretch;
  gap: 3.0625rem;
  @media (max-width: 70rem){
    display: none;
  }
`

const NavItem = styled.a`
  display: block;
  color: var(--blue);
  font-size: 0.75rem;
  line-height: 1.5833333333333333;
  letter-spacing: 0.02em;
  display: flex;
  text-align: center;
  align-items: center;
  font-weight: 600;
`

const Button = styled.div`
  position: relative;
  width: 2rem;
  z-index: 1001;
  display: none;
  cursor: pointer;
  @media (max-width: 70rem){
    display: block;
  }
  div {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: var(--blue);
    height: 2px;
    width: 1.5rem;
    transform: translate(-50%);
    transition: transform 0.4s, opacity 0.4s;
  }
  div:nth-child(1){
    transform: translate(-50%, calc(-50% + -0.5rem));
  }
  div:nth-child(2){
    
  }
  div:nth-child(3){
    transform: translate(-50%, calc(-50% + 0.5rem));
  }
  &.open {
    div {
      background-color: white;
    }
    div:nth-child(1){
      transform: translate(-50%) rotate(135deg);
    }
    div:nth-child(2){
      opacity: 0;
    }
    div:nth-child(3){
      transform: translate(-50%) rotate(45deg);
    }
  }
`

type HeaderProps = {

}
export const Header: FunctionComponent<HeaderProps> = () => {
  const [hide, setHide] = useState(false)
  const [open, setOpen] = useState(false)
  const outerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    let lastScrollY = window.scrollY
    const listener = () => {
      const scrollY = window.scrollY
      if(scrollY === 0 || scrollY < lastScrollY){
        setHide(false)
      } else {
        setHide(true)
      }
      lastScrollY = scrollY
    }
    listener()
    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  }, [])
  useEffect(() => {
    const outer = outerRef.current
    if(outer){
      const listener = () => {
        const {height} = outer.getBoundingClientRect()
        document.body.style.setProperty('--header-height', `${height}px`)
      }
      const observer = new ResizeObserver(listener)
      observer.observe(outer)
      listener()
      return () => {
        observer.disconnect()
      }
    }
  }, [])

  return <Outer ref={outerRef} className={classNames({hide: !open && hide})}>
    <RestoreScroll />
    <Inner>
      <Logo href="/">
        <SvgLogo1 />
      </Logo>
      <Nav>
        <NavItem href="/#about">
          <Text>
            ふるさとときめき<br />
            プロジェクトとは
          </Text>
        </NavItem>
        <NavItem href="/#shops">
          <Text>
            参加店(優待)<br />
            一覧
          </Text>
        </NavItem>
        <NavItem href="/#questionnaire">
          <Text>
            皆様の声を<br />
            お届けください
          </Text>
        </NavItem>
        <NavItem href="/#diners">
          <Text>
            ダイナース<br />
            クラブカード
          </Text>
        </NavItem>
      </Nav>
      <Button className={classNames({open})} onClick={() => setOpen(v => !v)}>
        <div />
        <div />
        <div />
      </Button>
      <Menu open={open} onClose={() => setOpen(false)}/>
    </Inner>
  </Outer>
}
