import { FunctionComponent, useEffect } from 'react'
import { throttle } from 'throttle-debounce'
import { isNotNullish } from '../utils/type-check'
import { querySelectorWithShadow } from '../utils/query-selector-with-shadow'
import { useApolloClient } from '@apollo/client'

type RestoreScrollProps = {

}
export const RestoreScroll: FunctionComponent<RestoreScrollProps> = () => {
  const client = useApolloClient()
  useEffect(() => {
    const listener = throttle(200, () => {
      history.replaceState({...history.state, scrollY: window.scrollY}, "")
    }, {})
    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  }, [])
  useEffect(() => {
    const listener = () => {
      const scrollY = history.state?.scrollY
      setTimeout(async () => {
        const queries = Array.from(client.getObservableQueries().values())
        Promise.all(queries.map(q => q.result())).then(() => {
          if(isNotNullish(scrollY)){
            window.scrollTo({top: scrollY ?? 0})
          } else {
            if(location.hash){
              querySelectorWithShadow(location.hash)?.scrollIntoView()
            }
          }
        })
      }, 500)
    }
    window.addEventListener('popstate', listener)
    listener()
    return () => {
      window.removeEventListener('popstate', listener)
    }
  }, [])
  useEffect(() => {
    const listener = () => {
      if(location.hash){
        if(location.hash === '#'){
          window.scrollTo({top: 0, behavior: 'smooth'})
        } else {
          querySelectorWithShadow(location.hash)?.scrollIntoView({behavior: 'smooth'})
        }
      }
    }
    window.addEventListener('hashchange', listener)
    return () => {
      window.removeEventListener('hashchange', listener)
    }
  })
  return null
}