import * as React from "react";
import type { SVGProps } from "react";
const SvgTi2W = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={36}
    fill="none"
    viewBox="0 0 40 36"
    {...props}
  >
    <path
      fill="#fff"
      d="M23.538 22.218h8.886v12.495h-8.886zM1.288 11.146h6.84v2.482a3.425 3.425 0 0 1-3.42 3.421 3.4 3.4 0 0 1-1.78-.501 3.43 3.43 0 0 1-1.64-2.92zm9.273-8.078-2.327 7.29H1.548l4.824-9.07h4.757zm5.693 2.99-.457 4.3H9.061l2.894-9.07h4.807zm6.124-4.77.45 4.24.514 4.83H16.59l.507-4.77.458-4.3zm5.598 0 .827 2.594 2.067 6.477h-6.736l-.451-4.241-.514-4.83zm-12.219 12.34a3.425 3.425 0 0 1-3.42 3.42 3.425 3.425 0 0 1-3.421-3.42v-2.482h6.841zm7.63 0a3.425 3.425 0 0 1-3.422 3.42 3.425 3.425 0 0 1-3.42-3.42v-2.482h6.841zm7.628 0a3.425 3.425 0 0 1-3.42 3.42 3.425 3.425 0 0 1-3.421-3.42v-2.482h6.841zm7.63 0a3.42 3.42 0 0 1-2.035 3.126 3.4 3.4 0 0 1-1.387.295 3.425 3.425 0 0 1-3.42-3.42v-2.483h6.841zm-5.086-12.34 4.823 9.07h-6.686l-2.894-9.07zM3.32 34.712V17.6c.435.152.901.236 1.387.236a4.21 4.21 0 0 0 3.814-2.434 4.21 4.21 0 0 0 3.815 2.434 4.21 4.21 0 0 0 3.814-2.434 4.21 4.21 0 0 0 3.815 2.434 4.21 4.21 0 0 0 3.814-2.434 4.21 4.21 0 0 0 3.815 2.434 4.21 4.21 0 0 0 3.814-2.434 4.21 4.21 0 0 0 3.815 2.434c.486 0 .952-.084 1.386-.236v17.113h-3.399V21.824a.394.394 0 0 0-.393-.394h-9.673a.394.394 0 0 0-.393.394v12.889zm33.683.787a.394.394 0 0 0 .393-.394V17.23a4.21 4.21 0 0 0 2.035-3.602v-2.875l-.001-.008a.4.4 0 0 0-.01-.078q-.001-.008-.005-.017a.4.4 0 0 0-.026-.07l-.005-.012-5.242-9.86A.4.4 0 0 0 33.795.5H6.135c-.145 0-.278.08-.347.209L.546 10.568l-.005.012a.4.4 0 0 0-.026.07l-.005.017a.4.4 0 0 0-.01.078v2.883a4.21 4.21 0 0 0 2.034 3.602v17.876c0 .218.177.394.394.394z"
    />
    <path
      fill="#fff"
      d="M18.73 27.808H6.882v-5.59h11.85zm.395-6.377H6.488a.394.394 0 0 0-.394.393v6.377c0 .218.177.394.394.394h12.637a.394.394 0 0 0 .393-.393v-6.378a.394.394 0 0 0-.393-.393"
    />
  </svg>
);
export default SvgTi2W;
