import * as React from "react";
import type { SVGProps } from "react";
const SvgTi5W = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={37}
    fill="none"
    viewBox="0 0 40 37"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m36.79 21.846.132-.038a2.663 2.663 0 0 0 1.757-3.333L33.855 2.877a2.66 2.66 0 0 0-3.332-1.757L2.88 9.67a2.664 2.664 0 0 0-1.76 3.332l1.406 4.548"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M34.127 14.343H5.192a2.665 2.665 0 0 0-2.665 2.665v16.327A2.665 2.665 0 0 0 5.192 36h28.935a2.665 2.665 0 0 0 2.665-2.665V17.008a2.665 2.665 0 0 0-2.665-2.665"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M36.792 17.862H2.527v4.27h34.265zM12.682 26.78H6.95a1.19 1.19 0 0 0-1.19 1.19v4.07c0 .657.533 1.19 1.19 1.19h5.732a1.19 1.19 0 0 0 1.19-1.19v-4.07a1.19 1.19 0 0 0-1.19-1.19M9.847 26.78v6.45"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.267 33.244V29h2.603M11.269 31.031h2.603M6.134 30.866h3.733M6.134 29h3.733M26.332 29.345h7.035M29.85 31.434h3.517M8.888 19.416h3.682M6.112 19.416h.83"
    />
  </svg>
);
export default SvgTi5W;
