import * as React from "react";
import type { SVGProps } from "react";
const SvgTi4W = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={37}
    fill="none"
    viewBox="0 0 36 37"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.908}
      d="M17.901 23.016c1.724 0 3.12-1.39 3.12-3.105a3.113 3.113 0 0 0-3.12-3.104 3.113 3.113 0 0 0-3.12 3.104 3.113 3.113 0 0 0 3.12 3.105M21.873 27.424V36M13.984 36v-8.576M4.952 36v-8.576M17.901 35.986v-3.3"
    />
    <circle cx={21.872} cy={32.688} r={0.565} fill="#0065AC" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.908}
      d="M17.935 23.016h-1.693c-2.333 0-5.08.317-5.08 5.155v2.84c0 1.238 1.01 2.24 2.257 2.24h.565M8.87 23.016c1.723 0 3.12-1.39 3.12-3.105a3.113 3.113 0 0 0-3.12-3.104 3.113 3.113 0 0 0-3.121 3.104 3.113 3.113 0 0 0 3.12 3.105"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.908}
      d="M8.903 23.016H7.21c-2.333 0-5.08.317-5.08 5.155v2.84c0 1.238 1.01 2.24 2.257 2.24h.565M30.919 36v-8.576M27.001 23.016c1.723 0 3.12-1.39 3.12-3.105a3.113 3.113 0 0 0-3.12-3.104 3.113 3.113 0 0 0-3.12 3.104A3.113 3.113 0 0 0 27 23.016"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.908}
      d="M26.967 23.016h1.694c2.332 0 5.08.317 5.08 5.155v2.84c0 1.238-1.01 2.24-2.258 2.24h-.564M17.935 23.016h1.694c2.333 0 5.08.317 5.08 5.155v2.84c0 1.238-1.01 2.24-2.258 2.24h-.564M34.87 3.258v6.774a2.257 2.257 0 0 1-2.258 2.258h-2.823l-2.822 2.823V12.29h-7.903l-1.129 2.823-1.694-2.823H8.903v2.823L6.08 12.29H3.258A2.257 2.257 0 0 1 1 10.032V3.258A2.257 2.257 0 0 1 3.258 1h29.354a2.257 2.257 0 0 1 2.258 2.258"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={0.908}
      d="M7.477 4.998h20.536M7.477 8.04h15.212"
    />
  </svg>
);
export default SvgTi4W;
